 .menu-container {
    width: var(--width-menu);
    background-color: #FFF;
    pointer-events: all;
    padding: 0 15px 15px 15px;
    z-index: 10;    
    font-size: var(--menu-font-size);
    font-family: var(--menu-font-family);
    display: flex;
    flex-flow: column;
	margin: auto;
}

#preferences > .menu-view {
    padding: 0 15px;
    display: flex;
    flex-flow: column;
    height: 100%;
}

#preferences > .row {
    margin-right: -15px;
    margin-left: -15px;
}

.menu-container > #title {
    color: #5f7e1a;
	font-size: 1.5vw;
}

.menu-container > #content {
    height: 100%;
    overflow-y: auto;
    padding-top: 15px;
}

#preferences > label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
}

.checkbox label, .radio label {
    min-height: 20px;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: 400;
    cursor: pointer;
}
