.app-menu-header {
	background: #fff;
    height: 162px;
}

.app-menu-header img {
	width:100%;
	max-width:300px;
}

.app-menu-header .signout-right {
    float: right;
    margin: 10px 0 0 0;
	text-align: right;
}

.title-bar {
	padding-top: 0;
	padding-left: 0;
	margin-bottom: 0;
}

.auth-button {
	margin: 10px;
	font-style: italic;
	color: #696a6b;
}

.nav-button {
	background-color: var(--color-main);
	color: #ffffff;
	padding: .5rem 1rem;
	margin: 0 1px;
	text-align: center;
	font-size: 10pt;
	display: inline-block;
	text-transform: uppercase;
	font-weight: bold;
	border-radius: .25rem;
}

.nav-button:hover, .nav-button.active {
	background-color: var(--color-highlight);
    color: black;
    text-decoration: none;
}

.home-icon {
	height: 10px;
}
