.map-canvas-container {
	padding: 10px;
    overflow: hidden;
	position: relative;
	display: inline;
	flex-grow: 2;
}

.map-legend {
	font-size: 0.6em;
	width: auto;
	background-color: #f2f2f2;
	position: absolute;
	bottom: 5px;
	left: 5px;
	border: thin solid gray;
	border-radius: 8px;
	box-shadow: 1px 1px 2px #888888;
	pointer-events: none;
	z-index: 5;
	margin: 5px;
	--inner-margin: 10px;
	font-weight: bold;
}

.map-legend > .legend-inner {
	padding: var(--inner-margin);
	margin: calc(-1*var(--inner-margin));
	display: flex;
	flex-flow: column;
}

.map-legend.show{
	bottom: var(--bottom-panel-height);
}

.map-legend > .legend-inner > .legend-group {
	flex: 0 1 auto;
	display: flex;
	flex-flow: row;
	align-items: center;
	padding: var(--inner-margin);
}

.map-legend > .legend-inner > .legend-group > .legend-circle {
	flex: 0 1 auto;
	padding-right: 5px;
}

.map-legend > .legend-inner > .legend-group > .legend-text {
	flex: 0 1 auto;
}

.map-legend > .legend-inner > .legend-group > .legend-img {
	flex: 0 1 auto;
	padding-bottom: var(--inner-margin);
	padding-right: calc(var(--inner-margin)/2);
}

.map-legend > svg:not(:root) {
	overflow: hidden;
}

.learning-map {
	align-content: center;
	overflow: hidden;
	width: 100%;
	height: 87vh;
}

.node-Info {
	font-size: var(--bottom-panel-font-size);
	font-family: var(--bottom-panel-font-family);
	background-color: #0000000d;
	display: none;
	width:100%;
	height: var(--bottom-panel-height);
	border: thin solid gray;
	z-index: 2;
	--inner-margin: 10px;
}

.node-Info .scroll {
    height: 150px;
	overflow-y: scroll;
	margin: 7px;
}

.scroll .row .col-1,.col-2 {
    font-weight: bold;
}

.node-Info .bot-header {
    background-color: var(--color-main);
	color: var(--text-color);	
	text-align: left;
	padding: 10px;
}

.node-Info .bot-close-col {
    text-align: center;
    background-color: var(--color-highlight);
    padding:10px;
	color: var(--text-color);
	border: 1px solid var(--color-highlight);
	cursor: pointer;
}

.node-Info.show {
	display: block;
}

.node-Info .row {
	margin:0px;
}

hr {
	border-top: 1px solid black;
	margin: 2px 0px 10px 0px;
}
