/** ==================================  */
/** Add CSS for standards dropdown info */
/** ==================================  */

.subject-table {
	padding-top: 3vh;
	margin-left: auto;
	margin-right: auto;
	max-width: 1050px;
}
