/* SideNav Styling */

#hamburgerIcon {
    background-color: var(--color-main);
    width: 5vw;
    position: relative;
    box-shadow: 5px 5px 20px#888888;
    top: 5px; 
    left: 0px;
    pointer-events: all;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 25%;
    border-bottom-right-radius: 25%;
    z-index: 1;
}
  
#hamburgerIcon > .menu-icon {
    width: 100%;
    height: 100%;
    position: relative;
	padding: 30% 24% 30% 10%;
    font-size: 4vw;
    color: #FFF;
    z-index: 1;
    cursor: pointer;
}
  
#side-panel {
    display: none;
    width: var(--width-side-panel);
    background-color: #FFF;
    color: #000;
    border-radius: 0 0 16px 0;
    padding-bottom: 15px;
    border-color: var(--color-main);
    border-width: thin;
    border-style: solid;
    font-size: var(--menu-font-size);
    pointer-events: all;
    font-family: var(--menu-font-family);
    z-index: 2;
}
  
.side-panel-container {
    width: 100%;
    display: flex;
    flex-flow: column;
    padding: 0 15px;
    height: 100%;
}
 
#side-panel > .side-panel-container > #tabs {
    background-color: var(--color-main);
    color: var(--text-color);
}

#side-panel > .side-panel-container > #tabs {
    background-color: var(--color-main);
}

#side-panel > .side-panel-container .side-panel-tab {
    padding-left: 0px;
    padding-right: 0px;
	cursor: pointer;
}

#side-panel > .side-panel-container .side-panel-tab.panel-open span {
    background-color: var(--color-highlight);
}

#side-panel > .side-panel-container .side-panel-tab span {
    position: relative;
    display: block;
    padding: 8px;
    text-align: center;
    font-size: 2vh;
    background-color: var(--color-main);
    width: 100%;
    color: var(--text-color);
    border-color: var(--color-main);
    border-width: thin;
    border-style: solid;
    border-radius: 4px;
    text-decoration: none;
}

#side-panel > .side-panel-container .side-panel-tab span:hover {
    background-color: var(--glow-color);
}

.container-fluid {
    padding: 15px 15px 0 15px;
    margin-right: auto;
    margin-left: auto;
}

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
}

#side-panel > .side-panel-container  #detailsDiv {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 70vh;
} 

#side-panel table {
    font-size: var(--menu-font-size);
}

label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
}

#detailsDiv button {
    width: 100%
}

#detailsDiv>div{
    margin-left: -5px;
    margin-right: -5px;
}

.standard-row {
    padding-top: 15px;
    padding-bottom: 15px;
    border-top: 1px solid #ddd;
}

#nodeTableBtns {
    float: right;
    margin-left: 10px;
    width: 100%;
    margin-bottom: 10px;
}

.tabContainer {
	display: inline-block;
}
