html, body {  
  --color-error: #FF0000;
  --color-highlight: #86d8ff;
  --color-main:  #0e76bc;
  --bottom-panel-height: 30%;
  --bottom-panel-font-family: var(--menu-font-family);
  --bottom-panel-font-size: 1vw;
  --bottom-panel-line-height: 20.8px;
  --glow-color: #86d8ff;
  --height-minimized-panel: 40%;
  --menu-font-size: var(--bottom-panel-font-size);
  --menu-font-family: Trebuchet, Helvetica, Arial, sans-serif;
  --table-hover-color: rgb(224, 224, 224);
  --text-color: #FFFFFF;
  --width-menu:30%;
  --width-side-panel: 40%;

  width: 100%;
  height: 100%;
}

.footer {
  padding-top: 10px;
  position: relative;
  bottom: 0;
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}

.titleRow {
  background-color: var(--color-main);
  color: var(--text-color);
  border-color: var(--color-highlight)
}

.titleRow h4 {
  font-weight: bold;
  padding-top: 12px;
}

.titleRow h3 {
  margin-left: 15px
}

 .titleRow > .col-1 {
  background-color: var(--color-highlight);
  color: var(--text-color);
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
}

.titleRow > .col-11 {
  vertical-align: middle;
  padding: 10px 5px 5px 5px;
}

.error-text {
	padding: 10px;
	color: #ff3223;
}

.quick-links-box {
	float: right;
	box-shadow: rgba(0, 0, 0, 0.1) 0 0 1px 1px;
	border: 1px solid #d7d7d7;
	background: white;
	padding: 1px;
	margin-bottom: 10px;
	width: 23.50598%;
}

.quick-links-title {
	width: 90%;
	border-bottom: 1px solid #d8d8d8;
	text-transform: uppercase;
	color: #5f7e1a;
	font-size: 1em;
	font-weight: 400;
	margin: 25px 0 0 10px;
	padding: 0 0 25px 0;
	display: block;
}

.quick-links-item {
	font-size: 1.2em;
	color: #696a6b;
	margin: 25px 0 10px 10px;
	text-decoration: none;
}

.quick-links-item:hover {
	color: var(--color-main);
	text-decoration: none;
}

.quick-links-ep-icon {
	background-image: url(./images/bigsprite.png);
	height: 40px;
	width: 100%;
	line-height: 40px;
	background-repeat: no-repeat;
	background-position: 0 -40px;
	display: block;
}

.quick-links-ep-icon:hover {
	background-position: 0 0;
}

.home-page-notice {
	margin: 5px;
	border: 2px solid red;
	padding: 3px;
}
