body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app-menu-header {
	background: #fff;
    height: 162px;
}

.app-menu-header img {
	width:100%;
	max-width:300px;
}

.app-menu-header .signout-right {
    float: right;
    margin: 10px 0 0 0;
	text-align: right;
}

.title-bar {
	padding-top: 0;
	padding-left: 0;
	margin-bottom: 0;
}

.auth-button {
	margin: 10px;
	font-style: italic;
	color: #696a6b;
}

.nav-button {
	background-color: var(--color-main);
	color: #ffffff;
	padding: .5rem 1rem;
	margin: 0 1px;
	text-align: center;
	font-size: 10pt;
	display: inline-block;
	text-transform: uppercase;
	font-weight: bold;
	border-radius: .25rem;
}

.nav-button:hover, .nav-button.active {
	background-color: var(--color-highlight);
    color: black;
    text-decoration: none;
}

.home-icon {
	height: 10px;
}

/** ==================================  */
/** Add CSS for standards dropdown info */
/** ==================================  */

.subject-table {
	padding-top: 3vh;
	margin-left: auto;
	margin-right: auto;
	max-width: 1050px;
}

/* SideNav Styling */

#hamburgerIcon {
    background-color: var(--color-main);
    width: 5vw;
    position: relative;
    box-shadow: 5px 5px 20px#888888;
    top: 5px; 
    left: 0px;
    pointer-events: all;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 25%;
    border-bottom-right-radius: 25%;
    z-index: 1;
}
  
#hamburgerIcon > .menu-icon {
    width: 100%;
    height: 100%;
    position: relative;
	padding: 30% 24% 30% 10%;
    font-size: 4vw;
    color: #FFF;
    z-index: 1;
    cursor: pointer;
}
  
#side-panel {
    display: none;
    width: var(--width-side-panel);
    background-color: #FFF;
    color: #000;
    border-radius: 0 0 16px 0;
    padding-bottom: 15px;
    border-color: var(--color-main);
    border-width: thin;
    border-style: solid;
    font-size: var(--menu-font-size);
    pointer-events: all;
    font-family: var(--menu-font-family);
    z-index: 2;
}
  
.side-panel-container {
    width: 100%;
    display: flex;
    flex-flow: column;
    padding: 0 15px;
    height: 100%;
}
 
#side-panel > .side-panel-container > #tabs {
    background-color: var(--color-main);
    color: var(--text-color);
}

#side-panel > .side-panel-container > #tabs {
    background-color: var(--color-main);
}

#side-panel > .side-panel-container .side-panel-tab {
    padding-left: 0px;
    padding-right: 0px;
	cursor: pointer;
}

#side-panel > .side-panel-container .side-panel-tab.panel-open span {
    background-color: var(--color-highlight);
}

#side-panel > .side-panel-container .side-panel-tab span {
    position: relative;
    display: block;
    padding: 8px;
    text-align: center;
    font-size: 2vh;
    background-color: var(--color-main);
    width: 100%;
    color: var(--text-color);
    border-color: var(--color-main);
    border-width: thin;
    border-style: solid;
    border-radius: 4px;
    text-decoration: none;
}

#side-panel > .side-panel-container .side-panel-tab span:hover {
    background-color: var(--glow-color);
}

.container-fluid {
    padding: 15px 15px 0 15px;
    margin-right: auto;
    margin-left: auto;
}

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
}

#side-panel > .side-panel-container  #detailsDiv {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 70vh;
} 

#side-panel table {
    font-size: var(--menu-font-size);
}

label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
}

#detailsDiv button {
    width: 100%
}

#detailsDiv>div{
    margin-left: -5px;
    margin-right: -5px;
}

.standard-row {
    padding-top: 15px;
    padding-bottom: 15px;
    border-top: 1px solid #ddd;
}

#nodeTableBtns {
    float: right;
    margin-left: 10px;
    width: 100%;
    margin-bottom: 10px;
}

.tabContainer {
	display: inline-block;
}

.map-canvas-container {
	padding: 10px;
    overflow: hidden;
	position: relative;
	display: inline;
	flex-grow: 2;
}

.map-legend {
	font-size: 0.6em;
	width: auto;
	background-color: #f2f2f2;
	position: absolute;
	bottom: 5px;
	left: 5px;
	border: thin solid gray;
	border-radius: 8px;
	box-shadow: 1px 1px 2px #888888;
	pointer-events: none;
	z-index: 5;
	margin: 5px;
	--inner-margin: 10px;
	font-weight: bold;
}

.map-legend > .legend-inner {
	padding: var(--inner-margin);
	margin: calc(-1*var(--inner-margin));
	display: flex;
	flex-flow: column;
}

.map-legend.show{
	bottom: var(--bottom-panel-height);
}

.map-legend > .legend-inner > .legend-group {
	flex: 0 1 auto;
	display: flex;
	flex-flow: row;
	align-items: center;
	padding: var(--inner-margin);
}

.map-legend > .legend-inner > .legend-group > .legend-circle {
	flex: 0 1 auto;
	padding-right: 5px;
}

.map-legend > .legend-inner > .legend-group > .legend-text {
	flex: 0 1 auto;
}

.map-legend > .legend-inner > .legend-group > .legend-img {
	flex: 0 1 auto;
	padding-bottom: var(--inner-margin);
	padding-right: calc(var(--inner-margin)/2);
}

.map-legend > svg:not(:root) {
	overflow: hidden;
}

.learning-map {
	align-content: center;
	overflow: hidden;
	width: 100%;
	height: 87vh;
}

.node-Info {
	font-size: var(--bottom-panel-font-size);
	font-family: var(--bottom-panel-font-family);
	background-color: #0000000d;
	display: none;
	width:100%;
	height: var(--bottom-panel-height);
	border: thin solid gray;
	z-index: 2;
	--inner-margin: 10px;
}

.node-Info .scroll {
    height: 150px;
	overflow-y: scroll;
	margin: 7px;
}

.scroll .row .col-1,.col-2 {
    font-weight: bold;
}

.node-Info .bot-header {
    background-color: var(--color-main);
	color: var(--text-color);	
	text-align: left;
	padding: 10px;
}

.node-Info .bot-close-col {
    text-align: center;
    background-color: var(--color-highlight);
    padding:10px;
	color: var(--text-color);
	border: 1px solid var(--color-highlight);
	cursor: pointer;
}

.node-Info.show {
	display: block;
}

.node-Info .row {
	margin:0px;
}

hr {
	border-top: 1px solid black;
	margin: 2px 0px 10px 0px;
}

 .menu-container {
    width: var(--width-menu);
    background-color: #FFF;
    pointer-events: all;
    padding: 0 15px 15px 15px;
    z-index: 10;    
    font-size: var(--menu-font-size);
    font-family: var(--menu-font-family);
    display: flex;
    flex-flow: column;
	margin: auto;
}

#preferences > .menu-view {
    padding: 0 15px;
    display: flex;
    flex-flow: column;
    height: 100%;
}

#preferences > .row {
    margin-right: -15px;
    margin-left: -15px;
}

.menu-container > #title {
    color: #5f7e1a;
	font-size: 1.5vw;
}

.menu-container > #content {
    height: 100%;
    overflow-y: auto;
    padding-top: 15px;
}

#preferences > label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
}

.checkbox label, .radio label {
    min-height: 20px;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: 400;
    cursor: pointer;
}

html, body {  
  --color-error: #FF0000;
  --color-highlight: #86d8ff;
  --color-main:  #0e76bc;
  --bottom-panel-height: 30%;
  --bottom-panel-font-family: var(--menu-font-family);
  --bottom-panel-font-size: 1vw;
  --bottom-panel-line-height: 20.8px;
  --glow-color: #86d8ff;
  --height-minimized-panel: 40%;
  --menu-font-size: var(--bottom-panel-font-size);
  --menu-font-family: Trebuchet, Helvetica, Arial, sans-serif;
  --table-hover-color: rgb(224, 224, 224);
  --text-color: #FFFFFF;
  --width-menu:30%;
  --width-side-panel: 40%;

  width: 100%;
  height: 100%;
}

.footer {
  padding-top: 10px;
  position: relative;
  bottom: 0;
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}

.titleRow {
  background-color: var(--color-main);
  color: var(--text-color);
  border-color: var(--color-highlight)
}

.titleRow h4 {
  font-weight: bold;
  padding-top: 12px;
}

.titleRow h3 {
  margin-left: 15px
}

 .titleRow > .col-1 {
  background-color: var(--color-highlight);
  color: var(--text-color);
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
}

.titleRow > .col-11 {
  vertical-align: middle;
  padding: 10px 5px 5px 5px;
}

.error-text {
	padding: 10px;
	color: #ff3223;
}

.quick-links-box {
	float: right;
	box-shadow: rgba(0, 0, 0, 0.1) 0 0 1px 1px;
	border: 1px solid #d7d7d7;
	background: white;
	padding: 1px;
	margin-bottom: 10px;
	width: 23.50598%;
}

.quick-links-title {
	width: 90%;
	border-bottom: 1px solid #d8d8d8;
	text-transform: uppercase;
	color: #5f7e1a;
	font-size: 1em;
	font-weight: 400;
	margin: 25px 0 0 10px;
	padding: 0 0 25px 0;
	display: block;
}

.quick-links-item {
	font-size: 1.2em;
	color: #696a6b;
	margin: 25px 0 10px 10px;
	text-decoration: none;
}

.quick-links-item:hover {
	color: var(--color-main);
	text-decoration: none;
}

.quick-links-ep-icon {
	background-image: url(/static/media/bigsprite.941c609b.png);
	height: 40px;
	width: 100%;
	line-height: 40px;
	background-repeat: no-repeat;
	background-position: 0 -40px;
	display: block;
}

.quick-links-ep-icon:hover {
	background-position: 0 0;
}

.home-page-notice {
	margin: 5px;
	border: 2px solid red;
	padding: 3px;
}

